import Head from "next/head";
import Link from "next/link";
export const siteTitle = "LEX CMS";
import { useRef, useEffect } from "react";
import SavedItems from '@/components/saved_items.js'; 
import {CartWrapper} from '@/lib/cart_context.js'; 
import ConditionalWrapper from '@/components/conditional_wrapper.js'
export default function PageLayout({ children, home, stylesheet, title, page_layout, favicon, meta_description, cart_enabled, pageBanner, breadcrumbs }) {
  const scrollUpButton = useRef();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    // window.addEventListener("scroll", scrollFunction);
    // return () => window.removeEventListener("scroll", scrollFunction);
  });
  const scrollFunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      scrollUpButton.current.style.right= "10px";
    } else {
      scrollUpButton.current.style.right = "-1000px";
    }
  };
  return (
    <>
      <Head>
        <title>{title ? title : siteTitle} </title>
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {favicon && <link href={favicon} type="image/x-icon" rel="icon" />}
      </Head>
        <ConditionalWrapper
          condition={cart_enabled}
          wrapper={children => <CartWrapper>{children}</CartWrapper>}>
        {cart_enabled &&
        <section className='lexcms-cart'>
          <SavedItems cartLink={'/lex-cms/saved-items/all'} />
        </section>
          }
        {pageBanner &&
          <div className='lexcms-banner-container' 
             style={{backgroundImage: `url(${pageBanner})`}}>
           </div>
        }
        {breadcrumbs &&
          <div className='lexcms-breadcrumbs'> 
            {breadcrumbs}
          </div>
          }
          <main className="lexcms-global-page-body-container">
            {children}
            <button ref={scrollUpButton} onClick={scrollToTop} className="lexcms-scroll-to-top">
              <span className="lexcms-scroll-to-top-text">Back to Top</span>
            </button>
          </main>
      </ConditionalWrapper>
    </>
  );
}
