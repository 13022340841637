import {createContext, useEffect, useState} from 'react';
const CartContext = createContext({})

export function CartWrapper(props) {
  const [items, setItems] = useState([])
  const [partNumbers, setPartNumbers] = useState({})
  const [cartKey, setCartKey] = useState()
  const [sharedCart, setSharedCart] = useState(); 
  const buildPartNumbersMap = (products) =>  {
    let map = {}
    products.forEach((d, i)  => {
      map[d.id] = d.part_numbers || [] ; 
    })
    return map;
  }

  const saveConfiguredProduct = async (event) => {
    let productId = event.detail.productId;
    let partNum =  '';
    if (event.detail.partNumber?.length > 1) {
      partNum =  event.detail.partNumber.join('|');
    }


    if (partNum.length > 1) {
      let ok = await addItem(parseInt(productId, 10), {cart_key: cartKey, part_number: partNum}) 
      if (ok) {
        setPartNumbers((old) => {
          let data =  Object.assign({}, old); 
          data[productId] = data[productId] || []
          data[productId].push(partNum)
          return data 
        })

      }
    }
  }

 const removeConfiguredPart = async (event) => {
    let productId = event.detail.productId;
    let partNum =  '';
    if (event.detail.partNumber?.length > 1) {
      partNum =  event.detail.partNumber.join('|');
    }
    if (partNum.length > 1) {
      removePart(parseInt(productId, 10), partNum) 

    }
  }

  const requestQuote = (event) => {
    let url = `/lex-cms/quote-request/new?from_cart=${cartKey}`
    if (event?.detail) { 
      let productId = event.detail.productId;
      let partNum =  '';
      if (event.detail.partNumber?.length > 0) {
        partNum =  event.detail.partNumber.join('|');
      }
      let products = window.btoa(JSON.stringify([{sku: partNum, product_id: productId, should_send: true }]))
      url =   `/lex-cms/quote-request/new?products=${products}` 
    }
    window.location = url;
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== 'test')  {
      fetch('/lex-api/cart/show')
        .then((res) => res.json())
        .then((data) =>   {
          setItems(data.products)
          setCartKey(data.uid)
          setPartNumbers(buildPartNumbersMap(data.products));
          window.cartPartNumbersInitial =  buildPartNumbersMap(data.products);

        }
        )
    }
  },[])
  

  useEffect(() => {
    const partsUpdatedEvent = new CustomEvent('partsChanged', {detail: {parts: partNumbers}})
    document.dispatchEvent(partsUpdatedEvent )

  }, [partNumbers])

   useEffect(() => {
    if (typeof window !== 'undefined' && cartKey) {
      document.addEventListener('savedPartNumber', saveConfiguredProduct)
    }
    return () => {
      document.removeEventListener('savedPartNumber', saveConfiguredProduct);
    }

  }, [cartKey])
  useEffect(() => {
    if (typeof window !== 'undefined' && cartKey) {
      document.addEventListener('requestQuote', requestQuote)
    }
    return () => {
      document.removeEventListener('requestQuote', requestQuote);
    }

  }, [cartKey])


   useEffect(() => {
    if (typeof window !== 'undefined' && cartKey) {
      document.addEventListener('removePartNumber', removeConfiguredPart)
    }
    return () => {
      document.removeEventListener('removePartNumber', removeConfiguredPart);
    }
  }, [cartKey])



  const itemInCart = (productId) => {
    return  items?.map((data) => data.id).includes(productId); 
  }

  const addItem = (productId, data) => {
    data  = data ? data : {cart_key: cartKey} 
    return fetch(`/lex-api/cart_items/${productId}`, {method: 'POST', body: JSON.stringify(data)})
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem adding this item to the cart");
        }
      })
      .then((data) => { setItems((old) => {
      if (!old.map((item, i) => item.id ).includes(productId)) {
        return [...old, {id: productId} ]  
      }
      else  {
       return old 
      }
      })
        return true;
      }
      )
      .catch((err) => alert(err)  )
  }

  const shareCart = () => {
    return fetch(`/lex-api/share_cart/${cartKey}`, {method: 'POST' })
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem sharing this cart.");
        }
      })
      .then((data) => {
        let segments = data.share_link.split('/') 
        let id = segments[segments.length - 1]; 
        const {protocol, host} = window.location 
        data.share_link =  `${protocol}//${host}/lex-cms/shared-saved-items/${id}`;
        return data
          } )
      .catch((err) => alert(err)  )
  }

  const clearCart = () => {
    return fetch(`/lex-api/cart/${cartKey}`, {method: 'DELETE' })
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem clearing this cart.");
        }
      })
      .then((data) => { window.location.reload(); } )
      .catch((err) => alert(err)  )
  }

 const removeItem = (productId) => {
    fetch(`/lex-api/cart_items/${productId}`, {method: 'DELETE', body: JSON.stringify({cart_key: cartKey})})
      .then((resp) => {
        if (resp.ok) { 
          return  resp.json()
        }
        else {
          throw Error("There was a problem removing this item from the cart");
        }
      })
      .then((data) => {setItems((old) => old.filter((data) => data.id !== productId )  )
           setPartNumbers((old) => {
             let data = Object.assign({}, old);
             data[productId] = [];
             return data;
           })
          }
         )
      .catch((err) => alert(err)  )
  }

 const removePart = (productId, part) => {
     fetch(`/lex-api/cart_items/${productId}`, {method: 'DELETE', body: JSON.stringify({cart_key: cartKey, part_number: part})})
      .then((resp) => {
        if (resp.ok) { 
          setPartNumbers((old) => {
            let data = Object.assign({}, old); 
            data[productId] = data[productId]?.filter((item) => item !== part )
            return data;
          })
          return  resp.json()
        }
        else {
          throw Error("There was a problem removing this part number from the saved item");
        }
      })
      .catch((err) => alert(err)  )
  }

  return (
    <CartContext.Provider value={{items: items, addItem: addItem, 
      removeItem: removeItem, itemInCart: itemInCart, 
      removePart: removePart,
      requestQuote: requestQuote,
      shareCart: shareCart, sharedCart: sharedCart, clearCart: clearCart} } > 
      {props.children}
    </CartContext.Provider>
  )
}
export { CartContext };

